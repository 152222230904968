@import '../../../assets/scss/vars';
@import 'vars';
.game-page {
    background-color: $Dark;
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center
}
.Green {
    background-color: $Success
}
.Red {
    background-color: $Error
}
.Blue {
    background-color: $Info
}
.Yellow {
    background-color: $Warning
}
.game-box {
    height: 100vh;
    width: $gameBoxSize;
    background-color: $MidnightBlue;
    position: relative;
    overflow: hidden;
    display: flex;
    &.ludo {
        .board {
            background-color: $White;
            position: relative;
            width: $gameBoxSize;
            height: $gameBoxSize;
            margin: auto;
            .player-home {
                .player-men-box {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(calc($gameBoxSize / 15), 1fr));
                    left: calc($gameBoxSize / 10);
                    top: calc($gameBoxSize / 10);
                    background-color: transparent;
                    width: calc($gameBoxSize / 5);
                    height: calc($gameBoxSize / 5);
                    position: absolute;
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $White;
                        height: calc($gameBoxSize / 60);
                        width: calc($gameBoxSize / 5);
                        top: calc(1.4 * $gameBoxSize / 15)
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $White;
                        width: calc($gameBoxSize / 60);
                        height: calc($gameBoxSize / 5);
                        left: calc(1.4 * $gameBoxSize / 15)
                    }
                    .player-men {
                        &.locked {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            max-width: calc($gameBoxSize / 15);
                            max-height: calc($gameBoxSize / 15);
                            background-color: $AshGray;
                            &:nth-child(1) {
                                
                            }
                            &:nth-child(2) {
                                
                            }
                            &:nth-child(3) {
                                
                            }
                            &:nth-child(4) {
                                
                            }
                        }
                    }
                }
                width: calc(2 * $gameBoxSize / 5);
                height: calc(2 * $gameBoxSize / 5);
                position: absolute;
                &::before {
                    position: absolute;
                    content: '';
                    left: calc($gameBoxSize / 30);
                    top: calc($gameBoxSize / 30);
                    width: calc($gameBoxSize / 3);
                    height: calc($gameBoxSize / 3);
                    background-color: transparent;
                    border: calc($gameBoxSize / 30) solid $White
                }
                &::after {
                    position: absolute;
                    content: '';
                    left: calc($gameBoxSize / 10);
                    top: calc($gameBoxSize / 10);
                    width: calc($gameBoxSize / 5);
                    height: calc($gameBoxSize / 5);
                    background-color: transparent;
                    border: calc($gameBoxSize / 60) solid $White
                }
                &.Green {
                    right: 0
                }
                &.Blue {
                    bottom: 0
                }
                &.Yellow {
                    right: 0;
                    bottom: 0
                }
            }
            .home {
                width: calc($gameBoxSize / 5);
                height: calc($gameBoxSize / 5);
                position: absolute;
                left: calc(2 * $gameBoxSize / 5);
                top: calc(2 * $gameBoxSize / 5);
                border-top: calc($gameBoxSize / 10) solid $Success;
                border-right: calc($gameBoxSize / 10) solid $Warning;
                border-bottom: calc($gameBoxSize / 10) solid $Info;
                border-left: calc($gameBoxSize / 10) solid $Error
            }
            .play-box {
                position: absolute;
                border: 0.5px solid $AshGray;
                width: calc($gameBoxSize / 15);
                height: calc($gameBoxSize / 15);
                display: grid;
                &.home-zone {
                    border: 0
                }
                &.star {
                    border: 0;
                    &:not(.start) {
                        background-color: $AshGray
                    }
                    &::after, &::before {
                        content: '';
                        position: absolute;
                        border: 10px solid transparent
                    }
                    &::after {
                        border-top: 17px solid $White;
                        top: 12px;
                        left: 8px
                    }
                    &::before {
                        border-bottom: 17px solid $White;
                        top: -4px;
                        left: 8px
                    }
                    &.start {
                        &::after {
                            border-top-color: $White
                        }
                        &::before {
                            border-bottom-color: $White
                        }
                    }
                }
            }
        }
    }
}
.game-console, .control-panel, .display-board {
    height: 100%;
    position: absolute;
    user-select: none;
    width: 100%
}
.control-panel {
    z-index: 10
}
.display-board {
    z-index: 1
}
.game-console {
    z-index: 1;
    .floor {
        border-top: 1px solid $White;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        height: 16px;
        border-bottom: 1px solid $White
    }
    .player {
        position: absolute;
        left: 20px;
        bottom: 47px;
        width: 20px;
        height: 60px;
        background-color: $Dark;
        border-radius: 10px;
        &.jump {
            animation: jump 0.7s linear
        }
    }
    .obstacle {
        position: absolute;
        left: 100%;
        bottom: 47px;
        width: 20px;
        height: 60px;
        background-color: $White;
        border-radius: 10px;
        &.run {
            animation: run 2s infinite linear
        }
    }
}
@keyframes run {
    0% {
        left: calc(100% + 50px)
    }
    100% {
        left: -50px
    }
}
@keyframes jump {
    0% {
        bottom: 47px
    }
    30% {
        bottom: 100px
    }
    50% {
        bottom: 160px
    }
    80% {
        bottom: 100px
    }
    100% {
        bottom: 47px
    }
}