@import './darkOverride';
.mv-10 {
    margin-top: -10px;
    margin-bottom: -10px
}
.mv10 {
    margin-top: 10px;
    margin-bottom: 10px
}
.mv20 {
    margin-top: 20px;
    margin-bottom: 20px
}
.mv30 {
    margin-top: 30px;
    margin-bottom: 30px
}
.mv40 {
    margin-top: 40px;
    margin-bottom: 40px
}
.mlauto {
    margin-left: auto
}
.mrauto {
    margin-right: auto
}
.mauto {
    margin: auto !important
}
.mt0 {
    margin-top: 0 !important
}
.mt20 {
    margin-top: 20px !important
}
.mt40 {
    margin-top: 40px !important
}
.mt60 {
    margin-top: 60px !important
}
.mt100 {
    margin-top: 100px !important
}
.mt10 {
    margin-top: 10px !important
}
.mt-20 {
    margin-top: -20px !important
}
.mr0 {
    margin-right: 0 !important
}
.mr10 {
    margin-right: 10px !important
}
.mr20 {
    margin-right: 20px !important
}
.mb0 {
    margin-bottom: 0 !important
}
.mb20 {
    margin-bottom: 20px !important
}
.mb30 {
    margin-bottom: 30px !important
}
.mb40 {
    margin-bottom: 40px !important
}
.mb50 {
    margin-bottom: 50px !important
}
.mb60 {
    margin-bottom: 60px !important
}
.mb10 {
    margin-bottom: 10px !important
}
.mbauto {
    margin-bottom: auto !important
}
.mb60-mbs40 {
    margin-bottom: 60px !important;
    @media (max-width: 600px) {
        margin-bottom: 40px !important
    }
}
.m50 {
    margin-top: 50px;
    margin-bottom: 50px
}
.ml0 {
    margin-left: 0 !important
}
.ml20 {
    margin-left: 20px !important
}
.ml-20 {
    margin-left: -20px !important
}
.ml10 {
    margin-left: 10px !important
}
.mt-5 {
    margin-top: -5px !important
}
.mt-10 {
    margin-top: -10px !important
}
.mtauto {
    margin-top: auto !important
}
.mt30 {
    margin-top: 30px !important
}
.mt50 {
    margin-top: 50px !important
}
.mh-20 {
    margin-left: -20px;
    margin-right: -20px
}
.mh-10 {
    margin-left: -10px;
    margin-right: -10px
}
.mt70 {
    margin-top: 70px !important
}
.mt80 {
    margin-top: 80px !important
}
.mh0 {
    @extend .ml0, .mr0
}
.mh20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
}
.m0 {
    @extend .mb0, .ml0, .mr0, .mt0
}
.pt0 {
    padding-top: 0 !important
}
.pl10 {
    padding-left: 10px
}
.pl20 {
    padding-left: 20px
}
.pl30 {
    padding-left: 30px
}
.pt60 {
    padding-top: 60px
}
.pt80 {
    padding-top: 80px
}
.pt100 {
    padding-top: 100px
}
.pt180 {
    padding-top: 180px
}
.pr0 {
    padding-right: 0 !important
}
.pr20 {
    padding-right: 20px !important
}
.pb0 {
    padding-bottom: 0 !important
}
.pb5 {
    padding-bottom: 5px !important
}
.pb20 {
    padding-bottom: 20px !important
}
.pl0 {
    padding-left: 0 !important
}
.p30 {
    padding: 30px !important
}
.p0 {
    @extend .pb0, .pl0, .pr0, .pt0
}
.pt120 {
    padding-top: 80px
}
.pv30 {
    padding-top: 30px;
    padding-bottom: 30px
}
.pv40 {
    padding-top: 40px;
    padding-bottom: 40px
}
.pb45 {
    padding-bottom: 45px
}
.pb60 {
    padding-bottom: 60px
}
.pb120 {
    padding-bottom: 80px
}
.pb40 {
    padding-bottom: 40px !important
}
.pb25 {
    padding-bottom: 25px
}
.pb95 {
    padding-bottom: 95px
}
.pb50 {
    padding-bottom: 50px !important
}
.pt25 {
    padding-top: 25px
}
.p20 {
    padding: 20px
}
.pv30 {
    padding-top: 30px;
    padding-bottom: 30px
}
.pv60 {
    padding-top: 60px;
    padding-bottom: 60px
}
.pb65 {
    padding-bottom: 65px
}
.pt40 {
    padding-top: 40px
}
.pt50 {
    padding-top: 50px !important
}
.pt110 {
    padding-top: 110px
}
.pv80 {
    padding-top: 80px;
    padding-bottom: 80px
}
.pl15 {
    padding-left: 15px
}
.pv120 {
    padding-top: 80px;
    padding-bottom: 80px
}
.pv50 {
    padding-top: 50px;
    padding-bottom: 50px
}
.pb45 {
    padding-bottom: 45px
}
.pt120 {
    padding-top: 80px
}
.pb100 {
    padding-bottom: 100px
}
.pt10 {
    padding-top: 10px
}
.pt20 {
    padding-top: 20px
}
.offset-4 {
    margin-left: 33.333333%
}
.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px
}
.p15 {
    padding: 15px !important
}
.pl60 {
    padding-left: 60px !important
}
.pr60 {
    padding-right: 60px !important
}
.p60 {
    padding: 60px !important
}
.pb10 {
    padding-bottom: 10px
}
.p120 {
    padding: 80px
}
.ml30 {
    margin-left: 30px
}
.pb150 {
    padding-bottom: 150px
}
.pt30 {
    padding-top: 30px !important
}
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding: 0 15px
}
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}
.pb30 {
    padding-bottom: 30px
}
.tac {
    text-align: center !important
}
.tal {
    text-align: left !important
}
.hidden {
    display: none !important
}
.relative {
    position: relative !important
}
.dark {
    color: $Dark !important
}
.flex {
    overflow: auto;
    display: flex !important
}
.block {
    display: block !important
}
.inline {
    display: inline !important
}
.inline-block {
    display: inline-block !important
}
.pointer {
    cursor: pointer !important
}
.lh1 {
    line-height: 1 !important
}
.lh2 {
    line-height: 2 !important
}
.fs10 {
    font-size: 10px !important
}
.fs12 {
    font-size: 12px !important
}
.fs18 {
    font-size: 18px !important
}
.fs24 {
    font-size: 24px !important
}
.fs32 {
    font-size: 32px !important
}
@media (min-width: 992px) {
    .mr20-desktop {
        margin-right: 20px !important
    }
}
@media (max-width: 991px) {
    .mr0-tab {
        margin-right: 0 !important
    }
}
.nowrap {
    white-space: nowrap
}
.warning {
    color: $Warning !important
}
.success {
    color: $Success !important
}
.error {
    color: $Error !important
}
.gray {
    color: $Gray !important
}
.white {
    color: $White !important
}
.orange {
    color: $Primary !important
}
.ashgray {
    color: $AshGray !important
}
.br0 {
    border-radius: 0 !important
}
.borderbottom {
    border-bottom: 1px solid $BorderColor
}
.border0 {
    border: 0 !important
}
.green {
    color: $Success
}
.primary {
    color: $Primary
}
.back-cream {
    background-color: $Cream
}
.width-full {
    width: 100% !important
}
.blank {
    user-select: none;
    color: transparent
}
.black {
    color: $Dark
}
.fw500 {
    font-weight: 500
}
.text-capital {
    text-transform: capitalize
}
.background-error {
    background-color: $Error !important;
    color: $White !important
}
.background-success {
    background-color: $Success !important;
    color: $White !important
}
.ph20 {
    padding-left: 20px !important;
    padding-right: 20px !important
}
.ph5 {
    padding-left: 5px !important;
    padding-right: 5px !important
}
.overflow-hidden {
    overflow: hidden !important
}
.overflow-auto {
    overflow: auto !important
}
.overflow-visible {
    overflow: visible !important
}
.bold {
    font-weight: bold !important
}