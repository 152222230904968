// Poppins
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins Italic'), local('Poppins-Italic.ttf'), url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Poppins SemiBold'), local('Poppins-Italic.ttf'), url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Poppins Light'), local('Poppins-Light.ttf'), url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins Regular'), local('Poppins-Regular.ttf'), url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Poppins Medium'), local('Poppins-Medium.ttf'), url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

// Montserrat
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin.ttf'), url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light.ttf'), url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular.ttf'), url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold.ttf'), url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Farsan
@font-face {
    font-family: 'Farsan';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Farsan Regular'), local('Farsan-Regular'), url('../fonts/Farsan/Farsan-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

// Flintstone
@font-face {
    font-family: 'Flintstone';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Flintstone'), local('Flintstone'), url('../fonts/Flintstone/FLINTSTO.TTF') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

// Gilroy
@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Gilroy RegularItalic'), local('Gilroy-RegularItalic.ttf'), url('../fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Gilroy SemiBold'), local('Gilroy-Italic.ttf'), url('../fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Gilroy Light'), local('Gilroy-Light.ttf'), url('../fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Gilroy Regular'), local('Gilroy-Regular.ttf'), url('../fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Gilroy Medium'), local('Gilroy-Medium.ttf'), url('../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

// Amita
@font-face {
    font-family: 'Amita';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Amita Regular'), local('Amita-Regular.ttf'), url('../fonts/Amita/Amita-Regular.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Amita';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Amita Bold'), local('Amita-Bold.ttf'), url('../fonts/Amita/Amita-Bold.ttf') format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}