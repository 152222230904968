.our-mission-section {
    display: flex;
    .col-6 {
        width: 50%;
        padding: 0 15px
    }
    &-left {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .our-mission-section-left-items {
            max-width: 350px;
            width: 50%;
            background-color: $Dark;
            color: $White;
            padding: 45px 35px 0 35px;
            overflow: hidden;
            .items-title {
                color: $White;
                font-size: 30px;
                line-height: 40px;
                font-weight: 700
            }
            p {
                color: $White;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400
            }
            .items-number {
                color: rgba(255, 255, 255, 0.2);
                font-size: 140px;
                line-height: 150px;
                font-weight: 700;
                transition: all 0.3s ease-in;
                display: flex;
                justify-content: flex-end;
                transform: translate(17%, 17%)
            }
        }
        &-items.white {
            background-color: $White;
            padding: 0 0 35px 20px;
            display: flex;
            justify-content: flex-end
        }
        &-items.border {
            border-top: 1px solid rgba(245, 245, 245, 0.1);
            border-right: 1px solid rgba(245, 245, 245, 0.1)
        }
    }
}