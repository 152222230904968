@import '../../../assets/scss/vars';
.testimonial {
    position: relative;
    padding-bottom: 40px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
        height: 400px
    }
    .testimonial {
        padding: 20px;
        .right-content-sec {
            padding: 30px;
            text-align: center;
            background-color: $White;
            box-shadow: 0 2px 3px $DarkGray;
            border-radius: 10px;
            i.quote {
                font-size: 200px !important;
                line-height: 54px;
                font-weight: bold;
                font-style: normal;
                color: $Primary;
                margin-top: 20px;
                display: block;
                margin-bottom: -40px;
                margin-top: 80px;
            }
            p {
                height: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.8
            }
            .honor-name {
                font-size: 20px;
                color: $Primary
            }
        }
        .left-img-sec img {
            margin: 30px auto 20px;
            width: 80px;
            width: 80px;
            border: 1px solid $BorderColor;
            border-radius: 50%;
        }
    }
}
