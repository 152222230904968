.productdetail-page {
    .main-product-detail {
        display: flex;
        margin-top: 80px;
        @media (max-width: 991px) {
            margin-top: 80px
        }
        .left-col-box {
            position: sticky;
            width: 40%;
            top: 80px;
            margin-bottom: auto;
            .left-col {
                display: flex;
                .gallery {
                    max-height: 460px;
                    overflow: auto;
                    padding: 0 5px;
                    width: 132px;
                    .img-tem {
                        border: 1px solid $BorderColor;
                        &:not(:last-child) {
                            margin-bottom: 20px
                        }
                        height: 100px;
                        padding: 5px;
                        cursor: pointer;
                        width: 100px;
                        overflow: hidden;
                        display: flex;
                        img {
                            margin: auto;
                            max-height: 90px;
                            max-width: 90px
                        }
                    }
                }
                .image-section {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-left: 20px;
                    display: flex;
                    padding: 32px 16px;
                    max-height: 560px;
                    border: 1px solid $BorderColor;
                    position: relative;
                    >div {
                        display: flex;
                        max-width: 100%;
                        margin: auto
                    }
                    .js-image-zoom__zoomed-area {
                        border: 1px solid $DarkGray !important;
                        cursor: crosshair
                    }
                    .js-image-zoom__zoomed-image {
                        border: 1px solid $ShadowColor;
                        background-color: $White;
                        left: 0 !important;
                        top: -17px !important
                    }
                    img {
                        margin: auto;
                        max-height: 320px
                    }
                    img.assuredImg {
                        width: 50px !important;
                        position: absolute;
                        right: 10px;
                        z-index: 9;
                        top: 10px;
                        height: 40px !important
                    }
                }
            }
        }
        .right-col {
            width: 60%;
            margin-left: 30px;
            position: relative;
            z-index: -1;
            .product-details {
                #price {
                    table {
                        margin: 0
                    }
                }
                .title {
                    margin: 0 0 8px 0;
                    word-break: break-word;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 24px
                }
                ul {
                    list-style: disc;
                    padding-left: 32px;
                    li {
                        padding-left: 8px
                    }
                }
                .starer {
                    @media (max-width: 991px) {
                    }
                }
                .feature {
                    a.a-link-normal {
                        padding-left: 5px;
                        text-decoration: none
                    }
                }
                span.customer-counting,
                .customer-answered {
                    padding-left: 5px;
                    vertical-align: text-bottom;
                    a {
                        text-decoration: none;
                        vertical-align: middle
                    }
                }
                .add-to-cart {
                    margin: 20px 0;
                    width: 150px
                }
            }
        }
    }
    .main-product-detail-page {
        .left-col-box {
            .gallery {
                .images-section {
                    display: flex;
                    .img-tem {
                        border: 1px solid $BorderColor;
                        margin-bottom: 20px;
                        height: 200px;
                        padding: 5px;
                        cursor: pointer;
                        width: 200px;
                        overflow: hidden;
                        margin-right: 20px;
                        display: flex;
                        img {
                            margin: auto
                        }
                    }
                }
            }
            .image-section {
                width: 80%;
                margin-left: 20px;
                display: flex;
                padding: 5px;
                border: 1px solid $BorderColor;
                overflow: hidden;
                margin-bottom: auto;
                min-height: 275px;
                img {
                    width: 100%;
                    display: block;
                    margin: auto
                }
            }
        }
    }
    .full-screen-content-sec {
        text-align: center;
        .content-box {
            margin-bottom: 50px
        }
        .content-block-first {
            text-align: center
        }
        .content-box {
            .content-block {
                width: 630px;
                margin: 0 auto
            }
        }
    }
    .half-part-img-content-sec {
        .sec-wrapper {
            width: 70%;
            display: flex;
            margin: auto;
            .image-section {
                width: 50%;
                flex: 1
            }
            .content-section {
                width: 50%;
                flex: 1;
                margin: auto
            }
        }
    }
    i {
        font-size: 22px;
        z-index: 9;
        cursor: pointer;
        &:hover, &.star {
            color: $Primary
        }
    }
    .user-image i {
        vertical-align: bottom
    }
    .user-name {
        font-size: 18px
    }
}