@import '../../../assets/scss/vars';
.sub-footer {
    background-color: $DarkWhite;
    padding: 40px 0;
    .sub-head {
        font-weight: 500;
        margin-right: 10px
    }
    a, .link {
        margin-right: 5px;
        font-size: 12px;
        color: $DarkGray !important;
        &:not(:last-child) {
            &::after {
                content: ','
            }
        }
    }
    a {
        &:focus, &:hover {
            color: $Primary !important
        }
    }
}