.modal {
    position: fixed;
    transition: all 0.3s ease-in-out;
    top: 100vh;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: $OverlayColor;
    display: flex;
    &.open {
        top: 0
    }
    .pricing-table-grid, .grid-Box {
        width: 100%
    }
    .modal-header {
        padding: 0 20px;
        border-bottom: 1px solid $BorderColor;
        h2.heading {
            margin-bottom: 0 !important;
            font-size: 16px;
            i {
                font-size: 16px !important;
                margin: 0 10px 0 -5px;
                display: inline-block;
                vertical-align: middle
            }
        }
    }
    .modal-content {
        background-color: $White;
        margin: auto;
        max-width: 600px;
        width: calc(100vw - 40px);
        border-radius: 10px;
        max-height: calc(100vh - 40px);
        min-height: 200px;
        position: relative;
        overflow: auto;
        &.overflown {
            overflow: unset
        }
        &.small{
            max-width: 400px
        }
        &.medium{
            max-width: 600px;
            min-height: 440px
        }
        &.big{
            max-width: 900px
        }
        button.close {
            position: absolute;
            right: 0;
            background-color: $Error;
            border: 0;
            color: $White;
            display: flex;
            width: 28px;
            z-index: 9;
            top: 0;
            height: 28px;
            i {
                margin: auto;
                display: block;
                font-size: 20px !important
            }
        }
        .modal-footer {
            padding: 20px;
            display: flex;
            justify-content: space-between
        }
        .modal-body {
            display: block;
            .modal-box {
                overflow: auto;
                margin: 15px 0;
                height: calc(100vh - 308px);
                max-height: 260px
            }
            .modal-footer {
                .discount-text {
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;
                    .MRP {
                        text-decoration: line-through;    
                        color: #b1a9a9;
                        padding-right: 10px
                    }
                    .offer-percent {
                        color: $Success
                    }
                }
                .modalbtn {
                    max-width: 180px;
                    margin: 20px auto
                }
            }
            .content-detail {
                li {
                    width: 50%;
                    float: left;
                    line-height: 22px;
                    list-style: none;
                    padding: 0 20px;
                    position: relative;
                    &::before {
                        content: '\f110';
                        position: absolute;
                        left: 0;
                        top: 6px;
                        font-family: "Flaticon";
                        width: 12px;
                        height: 12px;
                        border-radius: 20px;
                        text-align: center;
                        background: $Success;
                        color: $White;
                        font-size: 6px;
                        line-height: 13px
                    }
                    @media (max-width: 768px) {
                        width: 100%
                    }
                    i {
                        width: 17px;
                        height: 17px;
                        color: $Success;
                        vertical-align: sub
                    }
                }
            }
        }
    }
}

#image-modal {
    .preview-box {
        flex: 2;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $Cream
    }
    .setting-box {
        flex: 1;
        overflow: auto;
        textarea {
            min-height: 100px
        }
    }
}

#email-subscribe-modal {
    .modal-content {
        background: rgb(241,91,41);
        background: linear-gradient(90deg, rgba(241,91,41,1) 0%, rgba(237,131,44,1) 100%)
    }
    .subscribe-modal-body {
        display: flex
    }
    .image-section {
        min-width: 400px;
        position: relative;
        margin-left: -200px;
        max-width: 400px
    }
    .text-section {
        padding: 40px;
        .title {
            h4{
                text-transform: uppercase;
                font-size: 22px;
                letter-spacing: 2px;
                margin-bottom: 20px;
                font-weight: 400;
                color: $White
            }
            p {
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 10px;
                color: $White
            }
        }
        .email-secton {
            display: flex;
            .subscribe-input {
                width: 70%;
                display: block;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0
            }
            button {
                display: block;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                width: 30%;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px
            }
        }
    }
}

#car-service-status-modal {
    .modal-body {
        .no-data-icon {
            width: 80px;
            background: $BackGray;
            height: 80px;
            border-radius: 10px;
            margin: 20px auto;
            i {
                font-size: 40px;
                text-align: center;
                line-height: 80px;
                width: 60px;
                margin: auto;
                display: block;
                color: $AshGray
            }
        }
        .awited-text {
            text-align: center
        }
        .my-packages-assign-car {
            p {
                margin: 0
            }
        }
        ul.media-box {
            grid-gap: 20px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            li {
                background-color: $LightGray;
                display: inline-flex;
                padding: 10px;
                position: relative;
                max-width: 300px;
                flex-direction: column;
                img {
                    max-height: 300px
                }
            }
        }
    }
    label {
        width: 30%
    }
}
#preventive-checklist-modal {
    .modal-body {
        max-height: 550px;
        overflow: scroll;
        .query-list-status {
            td {
                padding: 10px !important
            }
        }
    }
}
.guest-modal-car-selector {
    .text-box-modal {
        text-align: center
    }
    .footer-guest-car-selector {
        ul {
            display: flex;
            li {
                padding: 0 10px;
                text-align: center;
                width: 100%;
                &:first-child{
                    border-right: 2px solid $PrimaryLight
                }
                p {
                    color: $AshGray;
                    line-height: 1.5;
                    label {
                        font-size: 22px;
                        color: $Dark
                    }
                }
            }
        }
    }
    .cartoon-img {
        img {
            width: 200px;
            margin: auto
        }
    }
    .rating-show-play-store {
        display: flex;
        align-items: center;
        font-size: 22px;
        justify-content: center;
        margin: auto;
        .rate-play-stor {
            font-size: 26px !important;
            padding: 0 10px
        }
        sub {
            margin-top: 10px;
            font-size: 12px
        }
    }
}
#trash-modal, #confirm-modal, #order-modal {
    .modal-body {
        text-align: center;
        i {
            font-size: 60px !important;
            margin: auto;
            display: block;
            color: $Primary
        }
        .btn-box-trash{
            justify-content: space-evenly;
            margin : 20px 0
        }
    }
}
#car-service-status-modal {
    .box {
        box-shadow: none !important;
        .head {
            display: none !important
        }
        .body {
            padding: 0;
            .daily-service-data {
                padding: 0;
                li {
                    list-style: none
                }
            }
        }
    }
}
body.modal-open {
    overflow: hidden
}