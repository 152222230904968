@import '../../../assets/scss/vars';
.copyright-area {
    color: $NavTextColor;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    .copyright-area-inner {
        padding: 20px 0;
        text-align: center;
        background-color: $FooterColor
    }
}