ul.pagination {
    list-style: none;
    margin: 0 auto 20px 0;
    padding: 0;
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    li {
        min-width: 42px;
        user-select: none;
        text-align: center;
        background-color: $Cream;
        line-height: 40px;
        cursor: pointer;
        color: $PrimaryLight;
        border-right: 1px solid $BorderColor;
        &.active {
            background-color: $Cream;
            color: $Dark;
            cursor: not-allowed
        }
    }
    .next {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px
    }
    .prev {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 210px
    }
    .next,
    .prev {
        padding: 0 13px;
        background-color: $Primary;
        color: $Cream;
        &.inactive,
        &.inactive {
            cursor: not-allowed;
            opacity: .5
        }
    }
}