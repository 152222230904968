.btn {
    cursor: pointer;
    display: inline-block;
    font-family: $generic_font;
    font-size: 14px !important;
    font-weight: 400;
    height: 40px;
    border-radius: 6px;
    text-transform: uppercase;
    border: 2px solid $Primary;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    padding: 0 20px !important;
    min-width: 80px;
    user-select: none;
    i {
        vertical-align: middle;
        margin-right: 8px
    }
    &.btn-outline {
        background-color: $White;
        color: $Primary !important;
        transition: 0.1s all linear;
        &:focus, &:hover {
            background-color: $Primary;
            color: $White !important
        }
    }
    &.btn-primary {
        background-color: $Primary;
        color: $White !important;
        transition: 0.1s all linear;
        &:focus, &:hover {
            background-color: $White;
            color: $Primary !important;
            span, a {
                color: $Primary !important;
            }
        }
    }
    &.btn-orange {
        background-color: $Orange;
        color: $White !important;
        transition: 0.1s all linear;
        &:focus, &:hover {
            background-color: $White;
            color: $Orange !important;
            span, a {
                color: $Orange !important;
            }
        }
    }
    &.btn-error {
        background-color: $Error;
        border-color: $Error;
        color: $White !important;
        transition: 0.1s all linear
    }
    &.btn-success {
        background-color: $Success;
        border-color: $Success !important;
        color: $White !important;
        transition: 0.1s all linear
    }
    &.btn-gray {
        background-color: $AshGray;
        color: $White !important;
        transition: 0.1s all linear;
        border: 1px solid $AshGray
    }
    &.btn-black {
        &:focus, &:hover {
            background-color: $White;
            color: $Dark !important;
            border: 2px solid $Dark
        }
    }
    &.btn-small {
        max-width: 200px;
        line-height: 30px !important;
        height: 34px;
        padding: 0 15px !important
    }
    &.btn-box {
        max-width: 160px;
        width: 100%
    }
    &.btn-half{
        width: 50%
    }
    &.btn-big {
        width: 200px;
        height: 50px !important;
        line-height: 48px !important;
        border-radius: 10px !important;
        font-size: 16px !important
    }
    &:disabled {
        cursor: not-allowed
    }
}