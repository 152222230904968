@import '../../../assets/scss/vars';
.preloader {
    display: none;
    .preloader-inner {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99999;
        background-color: rgba(2, 48, 71, 0.8);
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .cancel-preloader {
            position: absolute;
            bottom: 30px;
            right: 30px;
            a {
                background-color: $White;
                font-weight: 600;
                text-transform: capitalize;
                color: $Primary;
                width: 200px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                border-radius: 30px;
                display: block;
                -webkit-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;
                transition: all 0.3s ease-in;
                &:hover {
                    background-color: $Primary;
                    color: $White
                }
            }
        }
        .spinner {
            margin: 80px auto;
            width: 60px;
            height: 60px;
            position: relative;
            text-align: center;
            -webkit-animation: sk-rotate 2.0s infinite linear;
            animation: sk-rotate 2.0s infinite linear;
            .dot1, .dot2 {
                width: 60%;
                height: 60%;
                display: inline-block;
                position: absolute;
                top: 0;
                background-color: $Primary;
                border-radius: 100%;
                -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
                animation: sk-bounce 2.0s infinite ease-in-out
            }
            .dot2 {
                top: auto;
                bottom: 0;
                -webkit-animation-delay: -1.0s;
                animation-delay: -1.0s
            }
        }
    }
}