#root {
    &.theme-dark {
        color: $DarkWhite !important;
        .order-frame .inside-box .inside li {
            color: $DarkWhite !important;
            .name-title .strong {
                color: $DarkWhite !important
            }
        }
        .content {
            background-color: $Gray !important
        }
        &.admin {
            .box,
            .container .header nav ul,
            .modal .modal-content,
            .order-frame .inside-box,
            .grid-area-section .section,
            .sidebar, .sidebar .logo-link,
            .container .footer,
            .container .content ul.media-box li p,
            .container .header {
                background-color: $Black !important
            }
        }
    }
}