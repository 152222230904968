@import '../../../assets/scss/vars';
.latest-news {
    background-color: $White;
    position: relative;
    z-index: 0;
    &.style-02 {
        &::after {
            bottom: 22.5%
        }
        .latest-news-slider-items {
            .cards {
                .cards-body {
                    margin-left: 0;
                    transform: none;
                    background-color: transparent;
                    padding: 30px 0px 32px 0;
                    background-color: $OffWhite;
                    margin-left: 15px;
                    margin-top: -25px;
                    position: relative;
                    z-index: 9;
                    padding: 20px 30px;
                    height: 160px;
                    &:hover {
                        .name-date {
                            .author-name {
                                color: $Primary;
                                ::after {
                                    background-color: $Primary
                                }
                            }
                        }
                        .read-more-wrap {
                            .read-more {
                                color: $Primary
                            }
                        }
                    }
                    .name-date {
                        display: flex;
                        -ms-flex-wrap: nowrap;
                        flex-wrap: nowrap;
                        .author-name {
                            color: $Dark;
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 500;
                            width: 50%;
                            transition: all 0.3s ease-in
                        }
                        .publish-date {
                            width: 50%;
                            color: $Dark;
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 400
                        }
                    }
                    .heading-05 {
                        color: $Dark;
                        font-size: 22px;
                        line-height: 32px;
                        font-weight: 400;
                        a {
                            color: $Dark
                        }
                    }
                }
                .cards-img {
                    margin-right: 15px;
                    position: relative;
                    overflow: hidden;
                    transition: all 0.3s ease-in;
                    height: 200px;
                    object-fit: cover;
                    img {
                        width: 100%;
                        height: 100%
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        background-color: rgba(24, 24, 24, 0.3);
                        transition: all 0.3s ease-in
                    }
                    &:hover {
                        &::before {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%
                        }
                    }
                }
            }
        }
        .icon {
            padding-right: 10px
        }
        .latest-news-slider-progressbar {
            position: relative;
            overflow: hidden;
            .progress-bar {
                width: 100%;
                height: 3px;
                background-color: rgba(24, 24, 24, 0.3)
            }
            .porgress-bar-active {
                position: absolute;
                top: 0px;
                left: 0;
                background-color: $Primary;
                width: 25%;
                height: 3px
            }
        }
    }
    .icon {
        padding-right: 10px
    }
    .latest-news-slider-wrap {
        width: 100%;
        .latest-news-slider-active {
            display: flex
        }
    }
    .slider-arrow {
        display: flex;
        position: absolute;
        top: -10%;
        right: 15px;
        .slick-prev,
        .slick-next {
            color: $White;
            cursor: pointer;
            font-size: 22px;
            position: relative;
            z-index: 0
        }
        .slick-prev {
            order: 1;
            margin-right: 30px;
            ::before {
                position: absolute;
                left: -4px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                content: '';
                width: 20px;
                height: 20px;
                display: inline-block;
                border: 1px solid $White;
                border-radius: 50%;
                display: none;
                transition: all 0.3s ease-in
            }
            &:hover {
                &::before {
                    display: block
                }
            }
        }
        .slick-next {
            order: 2;
            ::after {
                position: absolute;
                right: -4px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                content: '';
                width: 20px;
                height: 20px;
                display: inline-block;
                border: 1px solid $White;
                border-radius: 50%;
                display: none;
                transition: all 0.3s ease-in
            }
            &:hover {
                ::after {
                    display: block
                }
            }
        }
    }
    &-slider-items .cards .cards-body .heading-05 {
        color: $Dark;
        font-size: 22px;
        line-height: 32px;
        font-weight: 600
    }
}