@import '../assets/scss/vars';
#root.front {
    .header-background .main-header {
        .right-header .btn-menu {
            color: $White
        }
        .left-header a {
            max-width: 60px
        }
    }
    .footer {
        background-image: none;
        .jumbo-container .footer-box-wrapper {
            @media (max-width: 600px) {
                padding-top: 60px
            }
            .footer-widget {
                .widget-title {
                    margin: 20px 0 0
                }
                .footer-logo img {
                    max-width: 100px !important
                }
            }
        }
    }
    .what-we-serve.back-cream {
        .container {
            > img {
                max-width: 560px;
                height: auto;
                margin-bottom: auto;
                @media (max-width: 991px) {
                    max-width: 480px
                }
            }
            @media (max-width: 768px) {
                flex-direction: column
            }
        }
    }
    .container {
        max-height: unset !important
    }
    .sub-header-banner {
        min-height: 32vw;
        &::before {
            content: '';
            position: absolute;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $OverlayColor
        }
        .sub-banner {
            position: relative
        }
    }
    li.navbarMobile-cart span {
        i {
            color: $Gray
        }    
    }
    .contact-page {
        flex-direction: row-reverse;
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column-reverse !important;
        }
        .left-image-section {
            width: auto !important;
            img {
                height: auto !important
            }
        }
        .right-form-section {
            width: auto !important;
            flex: 1
        }
        .contact-form-section {
            .form-group {
                &.input-box {
                    max-width: 440px
                }
            }
        }
    }
}